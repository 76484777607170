import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"

import { Spree, SpreeAPI } from "./spree-frontend"
// bootstrap_v5 loaded in spree-frontend
// import "bootstrap"
import { Tooltip } from "bootstrap"

// TOREVIEW:
//import './gems.js.erb'
import mainNav from './plugins/main_nav'
import backToTop from './plugins/back_to_top'
//import tinySlider from './plugins/tiny_slider'
import searchFilters from './plugins/search'

Rails.start()
Turbolinks.start()
// ActiveStorage.start()

document.addEventListener('turbolinks:load', function(){
  mainNav.init()
  backToTop.init()
  TinySlider.init()
  searchFilters.init()
  
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })
})
