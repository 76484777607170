var BTN_ID = 'btn-back-to-top'
var BREAKPOINT = 80 // 20

const backToTop = {
  btn: '',
  init(){
    backToTop.btn = document.getElementById(BTN_ID);

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () {
      backToTop.scrollFunction();
    };
    backToTop.btn.addEventListener("click", backToTop.scrollToTop);
  },
  scrollFunction(){
    if (document.body.scrollTop > BREAKPOINT || document.documentElement.scrollTop > BREAKPOINT) {
      backToTop.btn.style.display = "block";
    } else {
      backToTop.btn.style.display = "none";
    }
  },
  scrollToTop(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
}

export default backToTop
