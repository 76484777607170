import { tns } from 'tiny-slider/src/tiny-slider';
import { Modal } from "bootstrap"

const tinySlider = {
  init(){
    const sliderSelector = '.slider'
    document.querySelectorAll(sliderSelector).forEach((sliderContainer) => {
      let id = sliderContainer.getAttribute('id')
      let items = parseInt(sliderContainer.getAttribute('data-slider-items') || 1)
      let loop = (sliderContainer.getAttribute('data-slider-loop') === 'false') ? false : true
      let responsive = sliderContainer.getAttribute('data-slider-responsive') || {}
      let responsive_json = JSON.parse(responsive)

      let default_options = {
          container: `#${id}`,
          nav: false,
          items: items,
          mouseDrag: true,
          gutter: 10,
          center: true,
          edgePadding: 0,
          lazyload: true,
          responsive: responsive_json,
          swipeAngle: false,
          preventActionWhenRunning: true,
          loop: loop
          // preventScrollOnTouch: 'auto'
        }

      let controls_options = {}
      if (document.getElementById(`${id}-controls`)) {
        controls_options = {
          controlsContainer: `#${id}-controls`,
          prevButton: `#${id}-controls .slider-control-prev`,
          nextButton: `#${id}-controls .slider-control-next`
        }
      }
      let slider = tns({...default_options, ...controls_options })

      // this is causing the error of cycling after click:
      // let thumbnailsSliderId = sliderContainer.getAttribute('id').replace(/Gallery/, 'Thumbnails')
      // if (document.getElementById(thumbnailsSliderId)) {
      //   document.querySelectorAll(`#${thumbnailsSliderId} .slider-item`).forEach((thumb, i) => {
      //     thumb.addEventListener('click', function(){
      //       slider.goTo(i)
      //     })
      //   })
      // }

      let modalContainer = sliderContainer.getAttribute('data-slider-modal') || null
      if (modalContainer) {
        const activeClass = 'tns-slide-active'
        let modal = new Modal(document.getElementById(modalContainer))
        slider.getInfo().container.querySelectorAll('.slider-item:not(.tns-slide-cloned)').forEach((slide) => {
          slide.addEventListener('click', function(){
            modal.show()
          })
        })
        // TODO:
        // modalEl.addEventListener('show.bs.modal', function(event){
        //   let selected = slider.getInfo().index
        //   if (selected > 0) {
        //     let target = `#${modalContainer}-slider-item${selected}`
        //     let modalSlider = document.querySelector(`#${modalContainer}-slider`)
        //     modalSlider.querySelector(`.${activeClass}`).classList.remove(activeClass)
        //     modalSlider.querySelector(`#${modalContainer}-slider-item${selected}`).classList.add(activeClass)  
        //   }
        // })
      }
    })
  }
}

export default tinySlider
