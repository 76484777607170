//= require spree/frontend/viewport

Spree.fetchRelatedProducts = function (relatable, htmlContainer, locale) {
  return $.ajax({
    url: Spree.routes.product_related(relatable),
    data: {related_locale: locale}
  }).done(function (data) {
    htmlContainer.html(data)
    if (htmlContainer.find('.carousel').length > 0) {
      htmlContainer.find('.carousel').carouselBootstrap4()  
    }
    if (htmlContainer.find('.slider').length > 0) {
      TinySlider.init()
    }
  })
}

document.addEventListener('turbolinks:load', function () {
  var withRelatedProductsPage = $('body[id*=with-related-products]')

  if (withRelatedProductsPage.length) {
    var relatableId = $('div[data-related-products]').attr('data-related-products-id')
    var relatableType = $('div[data-related-products]').attr('data-related-products-type') || 'products'
    var relatable = {
      id: relatableId, type: relatableType
    }
    var relatedProductsEnabled = $('div[data-related-products]').attr('data-related-products-enabled')
    var relatedProducsLocale = $('div[data-related-products]').attr('data-related-products-locale')
    var relatedProductsFetched = false
    var relatedProductsContainer = $('#related-products')

    if (!relatedProductsFetched && relatedProductsContainer.length && relatedProductsEnabled && relatedProductsEnabled === 'true' && relatableId !== '') {
      $(window).on('resize scroll', function () {
        if (!relatedProductsFetched && relatedProductsContainer.isInViewport()) {
          Spree.fetchRelatedProducts(relatable, relatedProductsContainer, relatedProducsLocale)
          relatedProductsFetched = true
        }
      })
    }
  }
})
