import "bootstrap" // Bootstrap 5.X

// Content of vendor/assets/javascripts/spree/frontend/all.js
// require spree/frontend => spree_frontend/app/assets/javascripts/spree/frontend.js:

require("../../lib/spree_frontend/vendor/assets/javascripts/jquery.payment")
require("../../lib/spree_frontend/vendor/assets/javascripts/cleave")

import { Spree } from "../../lib/spree_frontend/app/assets/javascripts/spree.es6"
window.Spree = Spree
global.Spree = Spree

require("../../lib/spree_frontend/vendor/assets/javascripts/polyfill.min")
require("../../lib/spree_frontend/vendor/assets/javascripts/fetch.umd")
// require("../../lib/spree_frontend/app/assets/javascripts/spree/api/main")
import { SpreeAPI } from "../../lib/spree_frontend/app/assets/javascripts/spree/api/main.es6"
window.SpreeAPI = SpreeAPI
global.SpreeAPI = SpreeAPI

require("../../lib/spree_frontend/app/assets/javascripts/spree/lazysizes.config")
require("../../lib/spree_frontend/vendor/assets/javascripts/lazysizes.min")
require("../../lib/spree_frontend/vendor/assets/javascripts/accounting.min")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/account")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/api_tokens")
//require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/carousel-noconflict")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/cart")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/locale")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/currency")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/checkout")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/checkout/address")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/checkout/address_book")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/checkout/payment")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/checkout/shipment")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/views/spree/home/product_carousels")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/views/spree/layouts/spree_application")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/viewport")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/views/spree/product/related")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/views/spree/products/cart_form.es6")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/views/spree/products/description")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/views/spree/products/index")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/views/spree/products/modal_carousel")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/views/spree/products/price_filters")
// require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/views/spree/shared/carousel")
// require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/views/spree/shared/carousel/single")
// require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/views/spree/shared/carousel/swipes")
// require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/views/spree/shared/carousel/thumbnails")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/views/spree/shared/delete_address_popup")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/views/spree/shared/mobile_navigation")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/views/spree/shared/nav_bar")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/views/spree/shared/product_added_modal.es6")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/views/spree/shared/quantity_select")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/turbolinks_scroll_fix")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/main_nav_bar")
require("../../lib/spree_frontend/app/assets/javascripts/spree/frontend/login")

require("../../lib/spree_gift_line_items/app/assets/javascripts/spree/frontend/spree_gift_line_items")

import { SpreePaypalExpress } from "./plugins/spree_paypal_express.js"
window.SpreePaypalExpress = SpreePaypalExpress
global.SpreePaypalExpress = SpreePaypalExpress

import TinySlider from "./plugins/tiny_slider.js"
window.TinySlider = TinySlider
global.TinySlider = TinySlider

Spree.routes.api_tokens = Spree.pathFor('api_tokens')
Spree.routes.ensure_cart = Spree.pathFor('ensure_cart')
Spree.routes.api_v2_storefront_cart_apply_coupon_code = Spree.localizedPathFor('api/v2/storefront/cart/apply_coupon_code')
Spree.routes.api_v2_storefront_cart_remove_coupon_code = function(couponCode) { return Spree.localizedPathFor('api/v2/storefront/cart/remove_coupon_code/' + couponCode) }
Spree.routes.api_v2_storefront_destroy_credit_card = function(id) { return Spree.localizedPathFor('api/v2/storefront/account/credit_cards/' + id) }
Spree.routes.product = function(id) { return Spree.localizedPathFor('products/' + id) }
// Spree.routes.product_related = function(id) { return Spree.localizedPathFor('products/' + id + '/related') }
Spree.routes.product_related = function(relatable) { return Spree.localizedPathFor(relatable.type + '/' + relatable.id + '/related') }
Spree.routes.product_carousel = function (taxonId) { return Spree.localizedPathFor('product_carousel/' + taxonId) }
Spree.routes.set_locale = function(locale) { return Spree.pathFor('locale/set?switch_to_locale=' + locale) }
Spree.routes.set_currency = function(currency) { return Spree.pathFor('currency/set?switch_to_currency=' + currency) }

Spree.showProgressBar = function () {
  if (!Turbolinks.supported) { return }
  Turbolinks.controller.adapter.progressBar.setValue(0)
  Turbolinks.controller.adapter.progressBar.show()
}

Spree.clearCache = function () {
  if (!Turbolinks.supported) { return }

  Turbolinks.clearCache()
}

Spree.setCurrency = function (currency) {
  Spree.clearCache()

  var params = (new URL(window.location)).searchParams
  if (currency === SPREE_DEFAULT_CURRENCY) {
    params.delete('currency')
  } else {
    params.set('currency', currency)
  }
  var queryString = params.toString()
  if (queryString !== '') { queryString = '?' + queryString }

  SPREE_CURRENCY = currency

  Turbolinks.visit(window.location.pathname + queryString, { action: 'replace' })
}

export { Spree, SpreeAPI, SpreePaypalExpress }
