const searchFilters = {
  init(){
    // filter out search facets (filters)
    // input.search-filter[data-filter=<facetName>]
    // div.search-filterable[data-filter=<facetName>]
    document.querySelectorAll('input.search-filter').forEach((input) => {
      $(input).on("keyup", function() {
        var value = $(this).val().toLowerCase();
        var dataFilter = "[data-filter='" + $(this).data('filter') + "'] "
        var list = $("div.search-filterable" + dataFilter + "a")
        $(list).filter(function() {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
        $("div.search-empty" + dataFilter).toggle(!$(list).filter(':visible').length)
      });
    })
  }
}

export default searchFilters
