Spree.ready(function($) {
  Spree.onAddress = function() {
    if ($('#checkout_form_address').length) {
      var buyForGift = $('input#order_buy_for_gift')
      
      buyForGift.click(function() {
        updateGiftOrderForm(buyForGift)
      })
      updateGiftOrderForm(buyForGift)
    }

    function updateGiftOrderForm(buyForGift) {
      var giftLineItems = $('#gift_line_items')
      if (buyForGift.is(':checked')) {
        giftLineItems.show()
        var giftCheckboxes = $(giftLineItems).find('input[type=checkbox]')
        $.each(giftCheckboxes, function(index, giftCheckbox) {
          $(giftCheckbox).prop('disabled', false)
          $(giftCheckbox).on('click', function(){
            updateGiftLineItemForm($(this))  
          })
          updateGiftLineItemForm(giftCheckbox)
        })
      } else {
        giftLineItems.hide()
        $(giftLineItems).find('input[type=checkbox]').prop('disabled', true)
      }
    }

    function updateGiftLineItemForm(giftLineItem){
      var giftLineItemFields = $(giftLineItem).next().find('.gift-line-item-fields')
      //console.log(giftLineItemFields)
      if ($(giftLineItem).is(':checked')) {
        giftLineItemFields.show()
        giftLineItemFields.find('input, textarea').prop('disabled', false)
        giftLineItemFields.find('input[type=email]').focus()
      } else {
        giftLineItemFields.hide()
        giftLineItemFields.find('input, textarea').prop('disabled', true)
      }
      
    }
  }
  Spree.onAddress()
})
