let mainNav = {
  init() { 
    Array.from(document.getElementsByClassName("navbar-toggler")).forEach(function(toggler) {
      toggler.addEventListener("click", function(event) {
        toggler.classList.toggle("nav-open");
      });
    })
  }
}

export default mainNav;
