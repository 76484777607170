//= require spree/frontend/viewport

Spree.fetchProductCarousel = function (taxonId, htmlContainer, sectionId) {
  return $.ajax({
    url: Spree.routes.product_carousel(taxonId),
    data: {section_id: sectionId}
  }).done(function (data) {
    htmlContainer.html(data);
    if (htmlContainer.find('.carousel').length > 0) {
      htmlContainer.find('.carousel').carouselBootstrap4()  
    }
    if (htmlContainer.find('.slider').length > 0) {
      TinySlider.init()
    }
  })
}

Spree.loadCarousel = function (element, div) {
  var container = $(element)
  var productCarousel = $(div)
  var carouselLoaded = productCarousel.attr('data-product-carousel-loaded')

  if (container.length && !carouselLoaded && container.isInViewport()) {
    var sectionId = productCarousel.attr('data-product-carousel-section-id')
    var taxonId = productCarousel.attr('data-product-carousel-taxon-id')
    productCarousel.attr('data-product-carousel-loaded', 'true')
    Spree.fetchProductCarousel(taxonId, container, sectionId)
  }
}

Spree.loadsCarouselElements = function () {
  $('div[data-product-carousel-taxon-id]').each(function (_index, element) { Spree.loadCarousel(element, this) })
}

document.addEventListener('turbolinks:load', function () {
  var carouselPresent = $('div[data-product-carousel-taxon-id]')

  if (carouselPresent.length) {
    // load Carousels straight away if they are in the viewport
    Spree.loadsCarouselElements()

    // load additional Carousels when scrolling down
    $(window).on('resize scroll', function () {
      Spree.loadsCarouselElements()
    })
  }
})
